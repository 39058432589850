import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Text,
  Spinner,
  Flex,
  VStack,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import config from "../../config";

export default function CallDetails() {
  const { callId } = useParams();
  const [callDetails, setCallDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const { colorMode } = useColorMode();

  const conversationBg = useColorModeValue("gray.50", "gray.800");
  const user1BgColor = useColorModeValue("blue.100", "blue.600");
  const user2BgColor = useColorModeValue("green.100", "green.600");
  const messageTextColor = useColorModeValue("black", "white");
  const highlightBorderColor = useColorModeValue("yellow.400", "yellow.500");

  useEffect(() => {
    const fetchCallDetails = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${config.apiDomain}/calls_details/${callId}`,
        );
        if (!response.ok) {
          throw new Error(
            `Error fetching call details: ${response.statusText}`,
          );
        }
        const data = await response.json();
        const details = data.data;
        setCallDetails(details);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchCallDetails();
  }, [callId]);

  if (loading) {
    return (
      <Flex align="center" justify="center" height="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex align="center" justify="center" height="100vh">
        <Text>Error loading call details: {error}</Text>
      </Flex>
    );
  }

  if (!callDetails) {
    return (
      <Flex align="center" justify="center" height="100vh">
        <Text>No call details found.</Text>
      </Flex>
    );
  }

  const parseTranscription = (transcription) => {
    if (transcription === undefined || transcription == null) {
      return [];
    }
    const messages = transcription.split("\n").map((line, index) => {
      const isUser1 = line.startsWith("user:");
      const message = line.replace("user:", "").replace("agent:", "").trim();
      return {
        id: index,
        user: isUser1 ? "user" : "agent",
        message,
        isHighlighted: callDetails.is_highlighted?.[index] || false,
      };
    });
    return messages;
  };

  const messages = parseTranscription(callDetails.transcription);

  return (
    <Box p={4} maxWidth="800px" margin="0 auto">
      <Heading size="lg" mb={4} textAlign="center">
        Call Details
      </Heading>

      <Box
        p={4}
        mb={6}
        borderWidth="1px"
        borderRadius="lg"
        bg={colorMode === "light" ? "white" : "gray.700"}
        boxShadow="md"
      >
        <Text>
          <strong>Call ID:</strong> {callDetails.call_id}
        </Text>
        <Text>
          <strong>Date:</strong> {callDetails.date}
        </Text>
        <Text>
          <strong>Call / Email Status:</strong> {callDetails.email_status}
        </Text>
        <Text>
          <strong>Start Time:</strong> {callDetails.start_time}
        </Text>
        <Text>
          <strong>End Time:</strong> {callDetails.end_time || "N/A"}
        </Text>
        <Text>
          <strong>Details:</strong> {callDetails.submitted_data || "N/A"}
        </Text>
        {callDetails.recording_url && (
          <Box mt={4}>
            <Text mb={2}>
              <strong>Recording:</strong>
            </Text>
            <audio controls>
              <source src={callDetails.recording_url} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </Box>
        )}
      </Box>

      <Heading size="md" mb={4} textAlign="center">
        Conversation
      </Heading>
      <Box
        maxHeight="400px"
        overflowY="auto"
        p={4}
        borderWidth="1px"
        borderRadius="lg"
        bg={conversationBg}
        boxShadow="md"
      >
        <VStack spacing={4} align="stretch">
          {messages.map((msg) => (
            <Box
              key={msg.id}
              alignSelf={msg.user === "user" ? "flex-start" : "flex-end"}
              maxWidth="80%"
            >
              <Box
                bg={msg.user === "user" ? user1BgColor : user2BgColor}
                color={messageTextColor}
                px={4}
                py={2}
                borderRadius="lg"
                borderWidth={msg.isHighlighted ? "2px" : "0px"}
                borderColor={
                  msg.isHighlighted ? highlightBorderColor : "transparent"
                }
                boxShadow="sm"
              >
                <Text fontWeight={msg.isHighlighted ? "bold" : "normal"}>
                  {msg.message}
                </Text>
              </Box>
            </Box>
          ))}
        </VStack>
      </Box>
    </Box>
  );
}
